import axios from 'axios';
import { Token, config } from './';

//access token
const axiosConfig = {
    requestLogin: async function requestLogin(METHOD_TYPE, URI, DATA = null) {
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;
        // Make Request
        const response = await axios({
            method: method_type,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: DATA
        });
        return response
    },

    request: async function request(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;

        // Make Request
        const response = await axios({
            method: method_type,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
            data: DATA
        });
        if (response.status === 200 || response.status === 201) return response.data
        else return response.status
        //*/
        return response
    },

    requestSend: async function requestSend(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;

        const options = {
            method: method_type,
            url: url,
            data: DATA,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
            transformResponse: [(data) => {
                return data;
            }]
        };

        // send the request
        return axios(options);
    },
    requestDownload: async function requestDownload(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;

        const options = {
            method: method_type,
            url: url,
            data: DATA,
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
            transformResponse: [(data) => {
                return data;
            }]
        };
        // send the request
        axios(options);
    },

    requestUploadFile: async function requestDownload(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;

        const options = {
            method: method_type,
            url: url,
            data: DATA,
            responseType: 'blob', //Force to receive data in a Blob Format
            headers: {
                'Content-Type': 'mutlipart/form-data',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-Auth-Token': accessToken
            },
            transformResponse: [(data) => {
                return data;
            }]
        };
        // send the request
        axios(options);
    },

    requestSendFile: async function requestSendFile(METHOD_TYPE, URI, DATA = null) {
        const accessToken = Token.getAccessToken();
        const method_type = METHOD_TYPE.toLowerCase(),
            url = config.apiUrl + URI;
        
        const formData = new FormData();
        formData.append('avatar',DATA.data)
        //formData = JSON.stringify(formData)

        const options = {
            method: method_type,
            url: url,
            data: formData,
            headers: {
                'X-Auth-Token': accessToken,
                'Content-Type': `multipart/form-data; boundary=${formData.boundary}`,
            }
        };
        // send the request
        return axios(options);
        //*/
    },
}

export default axiosConfig;