class Ids {

    setIdPartner(idPartner) {
        this.idPartner = idPartner;
    }

    getIdPartner() {
        return this.idPartner;
    }

    setRole(role) {
        this.role = role;
    }
    getRole() {
        return this.role;
    }

}

export default new Ids();
