import { createSlice } from '@reduxjs/toolkit';
import { Token } from '../../config/';

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogged: false,
    user: null,
    token: null,
    loggedAt: null,
    idPartner: null,
    role: null,
    idOffer: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLogged = action.payload.isLogged;
      state.loggedAt = action.payload.loggedAt;
      state.idPartner = action.payload.idPartner;
      state.role = action.payload.role;
      state.idOffer = action.payload.idOffer;
    },
  },
});

export const { setUser } = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const loadUserAsync = (data, idPartner, role, idOffer) => dispatch => {
  Token.setAccessToken(data.authToken.token);
  const user = {
    user: data.user,
    token: data.authToken.token,
    isLogged: true,
    loggedAt: Date.now(),
    idPartner: idPartner,
    role: role,
    idOffer: idOffer,
  }
  setTimeout(() => {
    dispatch(setUser(user));
  }, 1000);
};

export const logoutUser = () => dispatch => {
  const user = {
    isLogged: false,
    user: null,
    token: null,
    loggedAt: null,
    idPartner: null,
    role: null,
    idOffer: null,
  }
  dispatch(setUser(user));
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = state => state.userReducer.user;
export const selectToken = state => state.userReducer.token;
export const selectIsLogged = state => state.userReducer.isLogged;
export const selectLoggedAt = state => state.userReducer.loggedAt;
export const selectIdPartner = state => state.userReducer.idPartner;
export const selectRole = state => state.userReducer.role;
export const selectIdOffer = state => state.userReducer.idOffer;

export default userSlice.reducer;
