import { axiosConfig } from '.'
import moment from "moment/moment";

const apiCall = {
  
  /****************************************************  Common routes ****************************************************/
  // Récuperer la liste des offres d'un partenaire
  getOffresPartenaire(iduser){
    return axiosConfig.request("GET", `/partenaire/offres/${iduser}`);
  },

  // Récuperer une offre d'un partenaire 
  getOffrePartenaireById(iduser, idoffer){
    return axiosConfig.request("GET", `/partenaire/offres/${iduser}/${idoffer}`);
  },
  
  // Login 
  postLogin(email, password) {
    return axiosConfig.requestLogin("POST", "/auth-tokens/partner", {
      email,
      password
    });
  },
  getUserByToken(token) {
    return axiosConfig.request("POST", "/laposte/login", {
      laposteIntegrationToken: token
    });
  },

  // Récuperer la liste des catégories
  getCategoryTypes(){
    return axiosConfig.request("GET", `/privilege/categories`);
  },

  // Ajouter une offre
  postAddOffre(id, userInfos) {
    const name = userInfos.name;
    const short_description = userInfos.short_description;
    const description = userInfos.description;
    const url = userInfos.url;
    const payment_url = userInfos.payment_url;
    const start_at = moment(userInfos.start_at).format('YYYY-MM-DD');
    const end_at = moment(userInfos.end_at).format('YYYY-MM-DD');
    const categorie_privilege_id = userInfos.categorie;

    return axiosConfig.requestSend("PUT", `/partner/offer/${id}/0`, {
      name,
      short_description,
      description,
      url,
      payment_url,
      start_at,
      end_at,
      categorie_privilege_id
    }
    );
  },

  // Modifier une offre
  editOffer(id, idoffer, userInfos) {
    const name = userInfos.name;
    const short_description = userInfos.short_description;
    const description = userInfos.description;
    const url = userInfos.url;
    const payment_url = userInfos.payment_url;
    const start_at = userInfos.start_at;
    const end_at = userInfos.end_at;
    const categorie_privilege_id = userInfos.categorie.toString();
    return axiosConfig.requestSend("PUT", `/partner/offer/${id}/${idoffer}`, {
      name,
      short_description,
      description,
      url,
      payment_url,
      start_at,
      end_at,
      categorie_privilege_id
    }
    );
  },

  // Supprimer une offre
  deleteOffer(iduser, idoffer) {
    return axiosConfig.request("DELETE", `/partenaire/offres/${iduser}/${idoffer}`);
  },

  // Ajouter une image
   postAddOffreImage(id, idoffer, data) {
    return axiosConfig.requestSendFile("POST", `/partner/offer/change/image/${id}/${idoffer}`, {
      data,
    }
    );
  },

  // Activer ou désactiver une offre
  editEnableOffer(id, idoffer, isEnabled) {
    const enable = isEnabled ? 1 : 0
    return axiosConfig.requestSend("PATCH", `/partner/offer/enable/${id}/${idoffer}/${enable}`
    );
  },

  //mot de passe oublié
  postPwdForgotten(email) {
    return axiosConfig.requestLogin("POST", "/pwd/forgotten", {
      email,
    });
  },
};
// ./ end

export default apiCall


